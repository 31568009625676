import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot } from "vue";
import { provide, ref } from 'vue';
import { mixpanelServiceTrack, splitPagePath } from '@ts/Util/analytics';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Accordion',
    props: {
        initialExpanded: {
            type: [String, (Array)],
            default: undefined
        },
        allowMultipleExpanded: {
            type: Boolean,
            default: false
        },
        mixpanelTrack: {
            type: Boolean,
            default: false
        },
        mixpanelProperties: {
            type: Object,
            default: () => ({
                commandName: 'TrackFAQInteraction',
                trackQuestionSlug: false,
                trackAllQuestions: false
            })
        }
    },
    setup(__props) {
        const props = __props;
        const currentExpanded = ref(props.initialExpanded);
        const firstClick = ref(false);
        const handleClick = (itemId) => {
            if (props.mixpanelTrack) {
                MixpanelFAQInteraction(itemId);
            }
            if (props.allowMultipleExpanded) {
                if (currentExpanded.value && Array.isArray(currentExpanded.value)) {
                    if (currentExpanded.value?.includes(itemId)) {
                        const index = currentExpanded.value?.indexOf(itemId);
                        currentExpanded.value?.splice(index, 1);
                    }
                    else {
                        currentExpanded.value?.push(itemId);
                    }
                }
            }
            else {
                currentExpanded.value = currentExpanded.value !== itemId ? itemId : undefined;
            }
        };
        const MixpanelFAQInteraction = (itemId) => {
            let commandName = props.mixpanelProperties?.commandName ?? 'TrackFAQInteraction';
            if (props.mixpanelProperties?.trackAllQuestions || !firstClick.value) {
                if (props.mixpanelProperties?.trackQuestionSlug) {
                    mixpanelServiceTrack(commandName, {
                        'questionSlug': itemId
                    });
                }
                else {
                    let page = splitPagePath();
                    mixpanelServiceTrack(commandName, {
                        'pageName': page,
                    });
                }
                firstClick.value = true;
            }
        };
        provide('currentExpanded', currentExpanded);
        provide('handleClick', handleClick);
        return (_ctx, _cache) => {
            return _renderSlot(_ctx.$slots, "default");
        };
    }
});
