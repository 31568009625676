import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import GoogleMapsSearchInput from '@modules/ClassSearch/GoogleMapsSearchInput.vue';
import Accordion from '@components/Accordions/Accordion.vue';
import AccordionItem from '@components/Accordions/AccordionItem.vue';
import CarouselShared from "@components/Core/CarouselShared.vue";
import Modal from '@components/Core/Modal.vue';
import { ref } from 'vue';
const LearnboutZumbaContainer = defineComponent({
    name: 'LearnboutZumbaContainer',
    components: {
        Accordion,
        AccordionItem,
        Modal,
        CarouselShared,
        GoogleMapsSearchInput,
    },
    setup() {
        const showZumbaVideoModal = ref(false);
        const active = ref('panel1a');
        const setActive = (panel) => {
            active.value = panel;
        };
        const toggleZumbaVideoModal = () => {
            showZumbaVideoModal.value = !showZumbaVideoModal.value;
        };
        return {
            showZumbaVideoModal,
            active,
            setActive,
            toggleZumbaVideoModal,
        };
    }
});
createApp(LearnboutZumbaContainer).mount('#learn-about-container');
