import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot } from "vue";
import { computed, inject } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccordionItem',
    props: {
        itemId: {
            type: String,
            required: true
        }
    },
    setup(__props) {
        const props = __props;
        const currentExpanded = inject('currentExpanded', undefined);
        // eslint-disable-next-line no-unused-vars
        const handleClick = inject('handleClick');
        const expanded = computed(() => {
            if (Array.isArray(currentExpanded?.value)) {
                if (currentExpanded) {
                    return currentExpanded.value.includes(props.itemId);
                }
            }
            return currentExpanded?.value === props.itemId;
        });
        return (_ctx, _cache) => {
            return _renderSlot(_ctx.$slots, "default", {
                expanded: expanded.value,
                handleClick: () => _unref(handleClick)(props.itemId)
            });
        };
    }
});
